//
//== DEFAULTS RAILS
  import Rails from "@rails/ujs"
  Rails.start()

//
//== JQUERY
  import $ from 'jquery'
  window.$ = window.jQuery = $


//
//== FANCYBOX
  import { Fancybox } from "@fancyapps/ui";


//
//== BOOTSTRAP
  import { Popper } from '@popperjs/core'
  import { Collapse, Modal } from 'bootstrap'